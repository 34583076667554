import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize, first } from 'rxjs/operators';

import { TransactionVerificationsService } from '../';

@Component({
    selector: 'app-data-verification-dialog',
    templateUrl: './data-verification-dialog.component.html',
    styleUrls: ['./data-verification-dialog.component.scss'],
    standalone: false
})
export class DataVerificationDialogComponent {
  public successfulStudies: string[] = [];
  public isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<DataVerificationDialogComponent>,
    private transactionVerificationsService: TransactionVerificationsService
  ) {
    this.retrieveSuccessfulStudies();
  }

  retrieveSuccessfulStudies(): void {
    this.isLoading = true;

    this.transactionVerificationsService
      .succesfulStudies()
      .pipe(
        first(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((successfulStudies) => {
        this.successfulStudies = successfulStudies;
      });
  }

  close(): void {
    this.dialogRef.close();
  }
}
