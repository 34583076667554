import { Component, Input } from '@angular/core';

import { Status } from './status-list.model';

@Component({
    selector: 'app-status-list',
    templateUrl: './status-list.component.html',
    styleUrls: ['./status-list.component.scss'],
    standalone: false
})
export class StatusListComponent {
  @Input() statuses: Status[];

  constructor() {}
}
