import { Component, OnInit } from '@angular/core';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { finalize, first } from 'rxjs/operators';

import { WorkQueueHistory, WorkQueueHistoryOptions, WorkQueuesService } from '../';

interface WorkQueueHistoryTimeframeOption {
  label: string;
  lookback: string;
  resolution: string;
  timestampFormat: DateTimeFormatOptions;
}

@Component({
    selector: 'app-work-queues-history',
    templateUrl: './work-queues-history.component.html',
    styleUrls: ['./work-queues-history.component.scss'],
    standalone: false
})
export class WorkQueuesHistoryComponent implements OnInit {
  public isLoading = false;
  public workQueueHistory: WorkQueueHistory;

  public timeframeOptions: WorkQueueHistoryTimeframeOption[] = [
    {
      label: 'Most Recent Day by Minute',
      lookback: 'P1D',
      resolution: 'minute',
      timestampFormat: DateTime.DATETIME_SHORT,
    },
    {
      label: 'Most Recent Week by Hour',
      lookback: 'P7D',
      resolution: 'hour',
      timestampFormat: DateTime.DATETIME_SHORT,
    },
    {
      label: 'Most Recent Month by Day',
      lookback: 'P30D',
      resolution: 'day',
      timestampFormat: DateTime.DATE_SHORT,
    },
    {
      label: 'Most Recent Year by Week',
      lookback: 'P1Y',
      resolution: 'week',
      timestampFormat: DateTime.DATE_SHORT,
    },
  ];
  public timeframe = this.timeframeOptions[0];

  constructor(private workQueuesService: WorkQueuesService) {}

  ngOnInit(): void {
    this.retrieveHistory();
  }

  retrieveHistory() {
    this.isLoading = true;

    const options: WorkQueueHistoryOptions = {
      resolution: this.timeframe.resolution,
      lookback: this.timeframe.lookback,
    };

    this.workQueuesService
      .history(options)
      .pipe(
        first(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((workQueuesHistory) => {
        this.workQueueHistory = workQueuesHistory;
      });
  }
}
