import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Breadcrumb, BreadcrumbsService } from './breadcrumbs.service';

@UntilDestroy()
@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    standalone: false
})
export class BreadcrumbsComponent implements OnInit {
  @Input() currentLabel: string;

  public breadcrumbs: Breadcrumb[];

  constructor(private breadcrumbsService: BreadcrumbsService) {}

  ngOnInit(): void {
    this.breadcrumbsService.breadcrumbs$.pipe(untilDestroyed(this)).subscribe((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    });
  }

  get hasBreadcrumbs(): boolean {
    return this.breadcrumbs && this.breadcrumbs.length > 0;
  }
}
