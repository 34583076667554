import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SessionRequest } from '../';

@Component({
    selector: 'app-session-requests-details',
    templateUrl: './session-requests-details.component.html',
    styleUrls: ['./session-requests-details.component.scss'],
    standalone: false
})
export class SessionRequestsDetailsComponent {
  constructor(
    public dialogRef: MatDialogRef<SessionRequestsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public sessionRequest: SessionRequest
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
