import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-content-placeholder',
    templateUrl: './content-placeholder.component.html',
    styleUrls: ['./content-placeholder.component.css'],
    standalone: false
})
export class ContentPlaceholderComponent implements OnInit {
  @Input() rows: number;
  @Input() paddingRight: number;
  @Input() padding: number;
  @Input() paddingLeft: number;

  public fakeRows: string[] = [];

  constructor() {}

  ngOnInit() {
    this.fakeRows = new Array(Number(this.rows)).fill('');
  }

  getContentStyle() {
    const styles = {
      paddingLeft: '0px',
      paddingRight: '0px',
    };

    if (this.padding) {
      styles.paddingLeft = `${this.padding}px`;
      styles.paddingRight = `${this.padding}px`;
    } else if (this.paddingLeft) {
      styles.paddingLeft = `${this.paddingLeft}px`;
    } else if (this.paddingRight) {
      styles.paddingRight = `${this.paddingRight}px`;
    }

    return styles;
  }

  getRowStyle(index: number) {
    return {
      width: index % 2 === 0 ? '100%' : '95%',
    };
  }
}
