import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Schedule, ScheduleOptions, SchedulesService } from '../';
import { BreadcrumbStateData } from '../../shared/breadcrumbs/breadcrumbs.service';

import { ListDataSource } from '../../shared/cdk';
import { SchedulesDetailsComponent } from '../schedules-details/schedules-details.component';

@UntilDestroy()
@Component({
    selector: 'app-schedules-list',
    templateUrl: './schedules-list.component.html',
    styleUrls: ['./schedules-list.component.scss'],
    standalone: false
})
export class SchedulesListComponent implements OnInit, AfterViewInit {
  public pageTitle = 'Schedules';
  public dataSource: ListDataSource<Schedule, SchedulesService>;
  public pageSizeOptions: number[] = [10, 25, 100];
  public initialPageSize = this.pageSizeOptions[0];
  public displayedColumns = [
    'googleMessageId',
    'status',
    'createdAt',
    'updatedAt',
    'sessions',
    'actions',
  ];

  public statuses = ['ACCEPTED', 'CONCLUDED'];
  public statusFilter = '';
  public searchFilter = '';

  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) public sort: MatSort;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private schedulesService: SchedulesService
  ) {}

  ngOnInit() {
    this.dataSource = new ListDataSource(this.schedulesService);
    this.paginator.pageSize = this.initialPageSize;
    this.sort.active = 'createdAt';
    this.sort.direction = 'desc';

    this.loadPage();
  }

  ngAfterViewInit() {
    this.sort.sortChange.pipe(untilDestroyed(this)).subscribe(() => this.loadPage(true));
    this.paginator.page.pipe(untilDestroyed(this)).subscribe(() => this.loadPage());
  }

  statusFilterChanged(): void {
    this.loadPage(true);
  }

  applySearchFilter(searchFilter: string): void {
    this.searchFilter = searchFilter;
    this.loadPage(true);
  }

  loadPage(resetPaginator = false): void {
    if (resetPaginator) {
      this.paginator.pageIndex = 0;
    }

    const params: ScheduleOptions = {};

    if (this.searchFilter) {
      params.searchFilter = this.searchFilter;
    }

    if (this.statusFilter) {
      params.status = this.statusFilter;
    }

    this.dataSource.query(
      `${this.sort.active},${this.sort.direction}`,
      this.paginator.pageSize,
      this.paginator.pageIndex,
      params
    );
  }

  openDetailsModal(schedule: Schedule): void {
    this.dialog.open(SchedulesDetailsComponent, {
      panelClass: ['comsig-dialog', 'details-dialog'],
      width: '700px',
      data: schedule,
    });
  }

  get breadcrumbStateData(): BreadcrumbStateData {
    return {
      breadcrumb: {
        key: 'schedules',
        label: this.pageTitle,
        route: '',
      },
    };
  }
}
