import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BridgeMap } from '../';

@Component({
    selector: 'app-bridge-maps-details',
    templateUrl: './bridge-maps-details.component.html',
    styleUrls: ['./bridge-maps-details.component.scss'],
    standalone: false
})
export class BridgeMapsDetailsComponent {
  constructor(
    public dialogRef: MatDialogRef<BridgeMapsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public bridgeMap: BridgeMap
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
