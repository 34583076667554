import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.scss'],
    standalone: false
})
export class DialogHeaderComponent {
  @Input() textAlign = 'center';

  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  closeClicked(): void {
    this.closeDialog?.emit();
  }
}
