import { Component, OnInit } from '@angular/core';
import { finalize, first } from 'rxjs/operators';
import { SessionRequestControl } from '../session-request-controls.model';

import { SessionRequestControlsService } from '../session-request-controls.service';

@Component({
    selector: 'app-session-requests-controls-list',
    templateUrl: './session-requests-controls-list.component.html',
    styleUrls: ['./session-requests-controls-list.component.scss'],
    standalone: false
})
export class SessionRequestsControlsListComponent implements OnInit {
  public displayedColumns: string[] = [
    'badMerchants',
    'badDates',
    'pauseDates',
    'comments',
    'createdAt',
    'createdBy',
    'actions',
  ];

  public dataSource: SessionRequestControl[];
  public isLoading = false;

  constructor(private sessionRequestControlsService: SessionRequestControlsService) {}

  ngOnInit(): void {
    this.retrieveList();
  }

  retrieveList(): void {
    this.isLoading = true;
    this.sessionRequestControlsService
      .getList()
      .pipe(
        first(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((sessionRequestControls) => {
        this.dataSource = sessionRequestControls.content;
      });
  }

  deactivate(sessionRequestControl: SessionRequestControl) {
    this.sessionRequestControlsService
      .setActive(sessionRequestControl, false)
      .pipe(first())
      .subscribe((sessionRequestControls) => {
        this.retrieveList();
      });
  }

  get hasDatasource() {
    return this.dataSource?.length > 0;
  }
}
