import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-work-queues-index',
    templateUrl: './work-queues-index.component.html',
    styleUrls: ['./work-queues-index.component.scss'],
    standalone: false
})
export class WorkQueuesIndexComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
