import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import { VERSION } from '../../environments/version';
import { ApiVersionInfo, Status, UIVersionInfo } from './status.model';
import { StatusService } from './status.service';

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
    standalone: false
})
export class StatusComponent implements OnInit {
  public health!: Status;
  public apiVersion: ApiVersionInfo;
  public uiVersion: UIVersionInfo;
  public showStatus = false;

  constructor(private statusService: StatusService) {}

  ngOnInit() {
    this.uiVersion = {
      version: VERSION.version,
      hash: VERSION.hash,
    };

    this.getApiStatus();
  }

  getApiStatus(): void {
    forkJoin(this.statusService.getStatus(), this.statusService.getVersion())
      .pipe(first())
      .subscribe(([health, version]) => {
        this.health = health;
        this.apiVersion = version;
        this.showStatus = true;
      });
  }

  isApiUp(): boolean {
    return this.health && this.health.status === 'UP';
  }
}
